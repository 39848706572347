@use '@ngneat/hot-toast/src/styles/styles.scss';
@import './theme/app';
@import './theme/overrides';
@import './theme/miscs';

.max-width-tooltip .mdc-tooltip__surface {
  max-width: unset !important;
  white-space: pre-line;
}

// Hot Toast teme
.notification-toast {
  min-width: 300px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  pointer-events: all !important;

  .toast-content {
    padding-left: 0.5rem !important;

    .toast-title {
      font-weight: 500;
      margin-bottom: 4px;
    }
    
    .toast-subtitle {
      font-size: 12px;
      opacity: 0.8;
    }
  }

  .hot-toast-message {
    text-align: left !important;
    justify-content: flex-start !important;
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .hot-toast-icon {
    align-self: center !important;
    padding-top: 0;
    margin-left: 0.65rem;
  }

  .hot-toast-close-btn {
    align-self: center !important;
    margin-right: 0.25rem;
    margin-top: 0;
  }

  .hot-toast-info-icon {
    background-image: url('./assets/notifications/info.svg') !important;
    background-size: 32px !important;
    background-position: left !important;
    background-repeat: no-repeat !important;
    padding-left: 32px !important;
    width: 32px !important;
    height: 32px !important;
    background-color: white !important;
    border-radius: 32px !important;

    &::after, &::before {
      display: none !important;
    }
  }

  .hot-toast-warning-icon {
    background-image: url('./assets/notifications/warning.svg') !important;
    background-size: 32px !important;
    background-position: left !important;
    background-repeat: no-repeat !important;
    padding-left: 32px !important;
    width: 32px !important;
    height: 32px !important;
    background-color: white !important;
    border-radius: 32px !important;

    &::after, &::before {
      display: none !important;
    }
  }

  .hot-toast-error-icon {
    background-image: url('./assets/notifications/action-required.svg') !important;
    background-size: 32px !important;
    background-position: left !important;
    background-repeat: no-repeat !important;
    padding-left: 32px !important;
    width: 32px !important;
    height: 32px !important;
    background-color: white !important;
    transform: rotate(0deg) !important;
    border-radius: 32px !important;

    &::after, &::before {
      display: none !important;
    }
  }

  padding: 12px !important;
  border-radius: 36px !important;
  font-size: 14px !important;
  
  &:hover {
    transform: translateY(-2px);
    transition: transform 0.2s ease;
    opacity: 0.9;
  }

  &.notification-action_required {
    border: 1px solid #FF6060 !important;
    backdrop-filter: blur(15px) !important;
    background-color: rgba(255, 255, 255, 0.8) !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  
  &.notification-warning {
    border: 1px solid #FB9318 !important;
    backdrop-filter: blur(15px) !important;
    background-color: rgba(255, 255, 255, 0.8) !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  
  &.notification-info {
    border: 1px solid #039855 !important;
    backdrop-filter: blur(15px) !important;
    background-color: rgba(255, 255, 255, 0.8) !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

