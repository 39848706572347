.mdc-button {
  border-radius: 9999px !important;
  padding: 20px 25px !important;

  &.mat-unthemed {
    text-decoration: underline;
  }
}

.mdc-text-field--outlined {
    .mdc-notched-outline {

        .mdc-notched-outline__leading {
            border-top-left-radius: 10px !important;
            border-bottom-left-radius: 10px !important;
        }

        .mdc-notched-outline__trailing {
            border-top-right-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
        }
    }
}

/* Change the border color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__trailing {
  border-color: #939BAD !important;
}

/* Change the border width */
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-width: 1px !important;
}

/* Change the border focused color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid).mdc-text-field--focused
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid).mdc-text-field--focused
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid).mdc-text-field--focused
  .mdc-notched-outline__trailing {
  border-color: #939BAD !important;
}

/* Change the border hover color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
  .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
  .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-color: #939BAD !important;
}

.mat-mdc-text-field-wrapper {
    background-color: #F8F8FA !important;
    border-radius: 10px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #F8F8FA inset !important;
}

.mdc-button {
  &.mat-primary {
    min-width: 150px
  }
}

.shadow-sm {
  box-shadow: 0 4px 4px rgba($color: #000000, $alpha: 0.05) !important;
}

h1 {
  >.mdc-icon-button {
    font-size: 16px !important;
    background-color: black !important;
    color: white !important;
    width: 32px !important;
    height: 32px !important;
    line-height: 32px !important;
    padding: 1px !important;
    margin-right: 0.5rem !important;
  }
}

.mat-stepper-vertical {
  border-left: solid thin #D1D6E2;
  border-right: solid thin #D1D6E2;

  .mat-vertical-content-container {
    margin-left: 0;
  }

  .mat-stepper-vertical-line {
    &::before {
      display: none;
    }
  }
  .mat-step-header {
    border-bottom: solid thin #D1D6E2;
    border-top: solid thin #D1D6E2;
  }

  .mat-step:last-child {
    border-bottom: solid thin #D1D6E2;
      .mat-step-header {
        &[ng-reflect-selected='true'] {
        border-top: none !important;
      }

      .mat-step-header {
        &:not([ng-reflect-selected='true']) {
          border-bottom: none !important;
        }
      }
    }

  }
}
/*
div.mat-mdc-select-panel {
  border: solid thin #939bad;
  border-top: none;
  box-shadow: none !important;
  position: absolute !important;
  left: -1px !important;
  top: -1px;
}

.mat-select {
  border-bottom-color: white
}

.mat-mdc-form-field {
  &.mat-focused {

      .mdc-notched-outline__leading {
        border-bottom-left-radius: 0 !important;
      }

      .mdc-notched-outline__trailing {
        border-bottom-right-radius: 0 !important;
      }
  }
}*/

.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  background-color: #f0f0f5 !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  font-size: 12px;
}

.mat-mdc-menu-panel {
  min-width: 150px !important;

  border: solid thin #939BAD;
  box-shadow: none !important;
  border-radius: 10px !important;


  .mat-mdc-menu-content {
    padding-top: 0;
    padding-bottom: 0;
  }

  .mat-mdc-menu-item-text {
    font-size: 14px !important;
    color: #1E1E22 !important;
    font-weight: 500 !important;
  }

  .mat-mdc-menu-item {
    &:not(:last-child) {
      border-bottom: solid thin #939BAD;
    }
  }

  &.nav-users-menu {
    border-radius: 0px !important;
    min-width: 201px !important;
    border-color: #d2d6e1;
    margin-top: 8px;

    .mat-mdc-menu-item {
      &:not(:last-child) {
        border-color: #d2d6e1;
      }
    }
  }
}

.mat-drawer-container {
  background-color: rgba(248,248,250,1) !important;
}

.mat-mdc-paginator-page-size-select {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    background-color: white;
    z-index: -1;
  }

  .mat-mdc-select {
    position: relative;
    z-index: 9;
  }
}

.mat-mdc-outlined-button:not(:disabled) {
  background-color: white;
  border-color: #939BAD !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  box-shadow: none !important;
  // border-radius: 0 !important;
  border: solid thin #D1D6E2 !important;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  border-bottom: solid thin #D1D6E2 !important;
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding-bottom: 5px;
  padding-top: 5px;

  &::before {
    display: none
  }
}

table {
  tr {
    &:hover {
      background-color: #F8F8FA;
    }
  }
}

.mdc-dialog__actions {
  padding-bottom: 2rem !important;
}

textarea {
  min-height: 64px !important;
}

.sticky-top {
  z-index: 990 !important;
}

.mat-button-toggle-group .mat-pseudo-checkbox {
  display: none !important;
}
