@import "variables";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  color: #1e1e22;
}

.border-grey {
  border: solid thin #d1d6e2;
}

.mat-drawer-container {
  background-color: rgba(248, 248, 250, 1);
  //background: linear-gradient(0deg, rgba(248,248,250,1) 0%, rgba(255,255,255,1) 100%) !important;
}

.blue-button {
  background-color: #0033ff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-width: 150px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.accept-button {
  background-color: rgba(96, 205, 28, 0.199);
  color: #60cd1c;
  padding: 5px 10px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-width: 120px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.reject-button {
  background-color: #be00001f;
  color: #be0000;
  padding: 5px 10px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-width: 120px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.red-button {
  background-color: #be0000;
  color: #ffffff;
  padding: 5px 10px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-width: 120px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.edit-button {
  background-color: #7272721f;
  color: #727272;
  padding: 5px 10px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-width: 120px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.debug-button {
  width: 40px; // Set the width to a fixed value
  height: 40px;
  border-radius: 50px;
  border: none;
  font-size: 16px;
  i {
    color: #0033ff;
  }
}

.blue-button:disabled {
  background-color: #cccccc; /* Gray background for disabled state */
  color: #666666; /* Dark gray text for disabled state */
  cursor: not-allowed;
}

.accept-button:disabled {
  background-color: #cccccc; /* Gray background for disabled state */
  color: #666666; /* Dark gray text for disabled state */
  cursor: not-allowed;
}

.reject-button:disabled {
  background-color: #cccccc; /* Gray background for disabled state */
  color: #666666; /* Dark gray text for disabled state */
  cursor: not-allowed;
}

.white-button {
  background-color: white;
  color: #0033ff;
  padding: 8px 20px;
  border: 2px solid #0033ff;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-width: 150px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.green-button {
  text-align: center;
  background-color: green;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  width: 250px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
  mat-sidenav {
    width: 0px;
  }
}

::ng-deep .mat-app-background {
  background-color: red !important;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.7);
}

.dialog-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 200px;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.7);
}

.blue-chip {
  background-color: #0033ff;
  color: white;
  text-align: center;
  border-radius: 30px;
  width: 45px;
  line-height: 20px;
  height: 20px;
  font-size: 12px;
}

.mat-snack-bar-container {
  &.snackbar-error {
    background: #d32f2f;
    color: white;
  }

  &.snackbar-success {
    background: #7fb800;
    color: white;
  }

  &.snackbar-info {
    background: #ffb400;
    color: white;
  }
}

.data-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 0;

  .mat-card & {
    margin-top: 1rem;
  }
}

.data-alert {
  @extend .data-loader;
  color: #00c2d0;
  font-size: 1rem;
  font-weight: 400;
}

.data-alert-wrapper {
  margin: 0 auto;
}

// TABLE RELATED CSS START
.mat-mdc-header-row {
  background: white !important;
}

.filters
  .mat-mdc-text-field-wrapper
  .mat-mdc-form-field-flex
  .mat-mdc-floating-label {
  top: 25px !important;
  font-size: 14px !important;
}

.filters .mat-mdc-text-field-wrapper {
  height: 50px !important;
}

.filters .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: rgba(220, 230, 253, 0.56) !important;
}

.filters .mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}

.table-container {
  border: 1px #d1d6e2 solid;
  margin: 0 !important;
  background-color: white;
}

.heading-filters {
  display: flex;
  align-items: center;
}

.heading-filters.posts {
  display: flex;
  align-items: center;
  justify-content: end;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1168px) {
  .heading-filters {
    flex-direction: column;
    align-items: flex-start; /* Align items to the start of the container */
  }

  .filters {
    margin: 10px 0 10px 0;
  }

  .heading {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 1400px) {
  .heading-filters.posts {
    flex-direction: column;
    align-items: flex-start; /* Align items to the start of the container */
  }

  .filters {
    margin: 10px 0 10px 0;
  }

  .heading {
    flex-direction: column;
    align-items: flex-start;
  }
}

// END TABLE RELATED CSS

// MAT-CHIPS - USED FOR STATUSES IN TABLES

.mat-mdc-standard-chip.status-chip {
  pointer-events: all !important;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-flex;
  padding: 12px 2px;
  border-radius: 33px;
  border: 1px solid transparent;
  align-items: center;
  cursor: default;
  min-height: 24px;
  height: 1px;
  color: #5f6368 !important;
  background-color: #f1f3f4 !important;
  font-size: 13px;
  font-weight: 500;
  pointer-events: none;
}

.mat-mdc-standard-chip.status-chip.processing {
  background-color: #0033ff;
  color: white;
}
.mat-mdc-standard-chip.status-chip.generated {
  background-color: rgba(96, 205, 28, 0.199) !important;
  --mdc-chip-label-text-color: #338500 !important;
}
.mat-mdc-standard-chip.status-chip.failed {
  background-color: #be00001f !important;
  --mdc-chip-label-text-color: #be0000 !important;
}

.mat-mdc-standard-chip.status-chip.pending {
  background-color: #0033ff;
  color: white;
}
.mat-mdc-standard-chip.status-chip.accepted {
  background-color: rgba(96, 205, 28, 0.199) !important;
  --mdc-chip-label-text-color: #338500 !important;
}
.mat-mdc-standard-chip.status-chip.active {
  background-color: rgba(96, 205, 28, 0.199) !important;
  --mdc-chip-label-text-color: #338500 !important;
}
.mat-mdc-standard-chip.status-chip.archived {
  background-color: #be00001f !important;
  --mdc-chip-label-text-color: #be0000 !important;
}
.mat-mdc-standard-chip.status-chip.paused {
  background-color: #0033ff;
  color: white;
}
.mat-mdc-standard-chip.status-chip.rejected {
  background-color: #be00001f !important;
  --mdc-chip-label-text-color: #be0000 !important;
}

.mat-mdc-standard-chip.status-chip.none {
  background-color: #0033ff;
  color: white;
}
.mat-mdc-standard-chip.status-chip.scheduled {
  background-color: #ffaf0e27 !important;
  --mdc-chip-label-text-color: #eda71f !important;
}

.mat-mdc-standard-chip.status-chip.publishing {
  background-color: #0033ff;
  color: white;
}
.mat-mdc-standard-chip.status-chip.published {
  background-color: rgba(96, 205, 28, 0.199) !important;
  --mdc-chip-label-text-color: #338500 !important;
}
.mat-mdc-standard-chip.status-chip.error {
  background-color: #be00001f !important;
  --mdc-chip-label-text-color: #be0000 !important;
}
.mat-mdc-standard-chip.status-chip.delivering {
  &.selected {
    background-color: #9C27B0 !important; /* Example color for selected state */
    --mdc-chip-label-text-color: white !important;
  }
  background-color: #F3E5F6 !important;
  --mdc-chip-label-text-color: #000000 !important;
}

.mat-mdc-standard-chip.status-chip.goal_met {
  &.selected {
    background-color: #FFCD4B !important; /* Example color for selected state */
    --mdc-chip-label-text-color: white !important;
  }
  background-color: #ffebb9 !important;
  --mdc-chip-label-text-color: #000000 !important;
}

.mat-mdc-standard-chip.status-chip.completed {
  &.selected {
    background-color: #019688 !important; /* Example color for selected state */
    --mdc-chip-label-text-color: white !important;
  }
  background-color: #8ee1d9 !important;
  --mdc-chip-label-text-color: #000000 !important;
}

.mat-mdc-standard-chip.status-chip.ready {
  &.selected {
    background-color: #389EF4 !important; /* Example color for selected state */
    --mdc-chip-label-text-color: white !important;
  }
  background-color: #9dd0fa !important;
  --mdc-chip-label-text-color: #000000 !important;
}

// END-MAT-CHIPS - USED FOR STATUSES IN TABLES

.icon-check {
  color: #60cd1c !important;
}

.icon-xmark {
  color: #be0000 !important;
}

.fa-pen {
  color: #322c39;
}

.overlay-content {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  background-color: #fff; /* Change to any color you like */
  padding: 10px; /* Add some padding */
  border-radius: 4px; /* Optional: rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for better visibility */
}

h1.back-header {
  margin-bottom: 0;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////NOTIFICATION//////////////////////////////////////////////////////////////////////////

.notification-pending {
  display: flex;
  min-width: 270px;
  max-width: 270px;
  background-color: #ffdfb0 !important;
  color: #a66b26 !important;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  i {
    margin-right: 10px;
  }
}

.notification-error {
  padding: 10px;
  background-color: #ffc4c4 !important;
  color: #ca4b4b !important;
  display: flex;
  align-items: center;
  min-width: 300px;
  max-width: 300px;
  border-radius: 40px;
  i {
    font-size: 30px;
    font-weight: 300;
    color: #ca4b4b !important;
    margin-right: 10px;
  }
}

.notification-warning {
  padding: 10px;
  background-color: #ffdfb0 !important;
  color: #a66b26 !important;
  display: flex;
  align-items: center;
  min-width: 300px;
  max-width: 300px;
  border-radius: 40px;
  i {
    font-size: 30px;
    font-weight: 300;
    color: #ca4b4b !important;
    margin-right: 10px;
  }
}

.notification-scheduled {
  display: flex;
  min-width: 430px;
  max-width: 430px;
  background-color: #ffaf0e27 !important;
  color: #eda71f !important;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  i {
    margin-right: 10px;
  }
}

.filter-container-wrapper {
  padding: 0 0px;
  box-sizing: border-box;
}
.filter-container {
  height: 54px;
  border-radius: 20px;
  width: calc(100%);

  h1 {
    font-size: 40px;
    color: $color-dark;
    font-weight: 700;
    margin: 0;
  }

  .filters-label {
    cursor: default;
    margin: 0;
    color: $color-dark;
    line-height: 18.68px;
    font-size: 14px;
    width: 75px;
  }
}

// search input
.search {
  background: white;
  border-radius: 10px;
  width: 230px !important;
  height: 41px !important;
  outline: none;
  padding: 0 10px;
  border: solid thin #939bad;
  color: $color-dark !important;
  font-weight: 700;
}

.search::placeholder {
  color: $color-dark !important;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.17px;
}

// styles for select redesign
// note this will work only if mat-select is without form-control
.select {
  background: white;
  border-radius: 10px;
  width: 200px !important;
  height: 41px !important;
  padding: 0;
  border: solid thin #939bad;
  /*
  &[aria-expanded="true"] {
    border-bottom-color: white;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
*/
  .mat-mdc-select-placeholder {
    color: $color-dark !important;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.17px;
  }

  .mat-mdc-select-value-text {
    font-weight: 700;
    font-size: 13px;
  }

  .mat-mdc-select-arrow svg {
    color: black !important;
  }

  .mat-mdc-select-trigger {
    height: 40px;
    padding: 0 10px 0 15px;
  }
}

.option {
  padding-right: 20px;
  font-size: 14px;

  .mat-pseudo-checkbox-full {
    margin-right: 10px !important;
  }

  .mdc-list-item__primary-text {
    font-weight: 500 !important;
    font-size: 14px !important;
  }
}

.mat-mdc-select-disabled {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.mat-mdc-paginator {
  background-color: transparent !important;
}

.mad-form {
  label {
    &:not(.mdc-label) {
      font-size: 14px;
      font-weight: 700;
      color: $color-dark;
    }
  }

  p.hint {
    font-size: 12px;
    position: relative;
    white-space: initial;
    margin-bottom: 1rem;

    &::before {
      width: 22px;
      height: 22px;
      content: "";
      display: block;
      position: absolute;
      left: -28px;
      background-image: url("../assets/hint-icon.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  h2 {
    font-size: 30px;
    font-weight: bold;
    font-family: "Inter", sans-serif;
  }
}

.form-hint {
  font-size: 12px;
  position: relative;
  white-space: initial;
  margin-bottom: 1rem;

  &::before {
    width: 22px;
    height: 22px;
    content: "";
    display: block;
    position: absolute;
    left: -28px;
    background-image: url("../assets/hint-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

.button-shared-edge {
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  background-color: #eeeff4;
  font-size: 16px;
  border: solid thin #939bad;
  transition: all 0.3s;

  &.bse-large {
    padding: 14px 17px !important;
  }

  &:hover {
    background-color: #fff;
  }

  i {
    color: #939bad;
  }

  &.btn-left {
    border-radius: 10px 0 0 10px;
    border-right: none;
  }

  &.btn-right {
    border-radius: 0 10px 10px 0; /* Round right side */
  }

  &.active {
    background-color: #fff;
    color: black;
  }

  &.active i {
    color: black;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

table {
  td {
    font-size: 12px;
    a {
      color: #322c39 !important;
      font-weight: bold;
    }
  }
}

.mat-mdc-checkbox {
  .mdc-label {
    display: flex;
    img {
      width: 20px;
      margin-right: 0.5rem;
    }
  }
}

.mat-mdc-cell.mat-column-actions {
  border-left: solid thin #d1d6e2;
  width: 80px;
}

.mat-mdc-dialog-content {
  .header {
    color: $color-dark !important;
    font-size: 30px;
    font-weight: bold;
    font-family: "Inter", sans-serif;
  }

  .sub-header {
    color: $color-dark !important;
    font-size: 16px;
    font-weight: bold;
    font-family: "Inter", sans-serif;
  }

  label {
    font-weight: 700;
    font-size: 14px;
    color: #322c39 !important;
  }
}

.cursor-pointer {
  > * {
    cursor: pointer !important;
  }
}

.disabled-pointer-events {
  pointer-events: none !important;
  opacity: 0.4 !important;
}


.buttonLoader:after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: $color-dark;
  animation: spinning .8s linear infinite;
}

@keyframes spinning {
  to {transform: rotate(360deg);}
}


.card-container {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  flex-wrap: wrap;

  .card {
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    flex: 1 1 calc(33.333% - 1rem);
    max-width: calc(33.333% - 1rem);
    display: flex;
    flex-direction: column;
    background-size: cover;
    max-height: 280px;
    min-height: 280px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(to top, white 57%, transparent 100%);
      z-index: 1;
    }

    &:hover {
      transform: translateY(-8px);
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      filter: grayscale(1);
    }

    .card-content {
      position: relative;
      z-index: 2;
      margin-top: auto;
      padding: 1rem;

      h3 {
        font-size: 1.25rem;
        margin: 0 0 0.5rem;
      }

      p {
        font-size: 0.9rem;
        margin: 0 0 1rem;
        color: #666;
      }

      .image-wrapper {
        position: relative;
        display: inline-block;
        margin-left: 0.5rem;
        margin-bottom: 1.5rem;
        img {
          display: block;
          width: 40px;
        }
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background-color: #ffffff;
          width: 60px;
          height: 60px;
          z-index: -1;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .card {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
}
